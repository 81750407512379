<template>
    
    <v-container>

        <div class="px-2">
                
            <h1 class="mt-8 d-flex align-center">

                {{ $t('Accommodations') }}
                <v-spacer></v-spacer>

                <Sync @close="get()" />

            </h1>

            <v-breadcrumbs
                :items="breadcrumbs"
                large class="pl-0 pt-2"
            ></v-breadcrumbs>
        
        </div>

        <div class="d-flex align-stretch flex-wrap">

            <v-card
            v-for="acco in accoData"
            :key="acco.id"
            class="ma-2" min-width="300" width="300">

                <v-img v-if="acco.data().meta.thumb" height="200" :src="acco.data().meta.thumb"></v-img>
                
                <v-card-title>{{ acco.data().name }}</v-card-title>

                <div class="pa-4">

                    <v-text-field
                        outlined hide-details  type="number"
                        :value="acco.data().lat"
                        label="Lat"
                        @change="update('lat', $event, acco)"
                    ></v-text-field>
                    
                </div>

                <div class="pa-4">
               
                    <v-text-field
                        outlined hide-details type="number"
                        :value="acco.data().long"
                        @change="update('long', $event, acco)"
                        label="Long"
                    ></v-text-field>

                </div>

                <v-divider></v-divider>

                <div class="pa-4 d-flex align-center">

                    <v-btn outlined block
                    :color="app.state.style.primary"
                    v-if="acco.data().hide_website" @click="update('hide_website', false, acco, true)">
                        
                        <v-avatar
                            size="20"
                            color="red"
                            class="mr-2"
                        >
                        </v-avatar>

                        <v-spacer></v-spacer>

                        {{ $t('Hidden') }}
                        
                    </v-btn>

                    <v-btn outlined block
                    :color="app.state.style.primary"
                    v-else @click="update('hide_website', true, acco, true)">
                        
                        <v-avatar
                            size="20"
                            color="green"
                            class="mr-2"
                        >
                        </v-avatar>

                        <v-spacer></v-spacer>

                        {{ $t('Shown') }}
                        
                    </v-btn>

                </div>

            </v-card>
            
        </div>
        
    </v-container>
    
</template>

<script>
    
import { inject, ref, onMounted } from '@vue/composition-api' ;
import Sync from '@/components/administrations/Sync' ;
import accommodations from "@/store/accommodations/accommodations" ;
import firebase from 'firebase' ;

export default {

    components: {
        Sync
    },
    provide: {
        accommodations
    },
    setup(props, ctx){
    
        const app = inject('app') ;
        const currentWebsite = inject('currentWebsite') ;

        const accoData = ref([]) ;
        const loading = ref(false) ;

        const get = async () => {

            accoData.value = [] ;
            loading.value = true ;

            let args = {} ;
            
            accommodations.get(args)
            .then((querySnapshot) => {

                querySnapshot.forEach((doc) => {
                    accoData.value.push(doc);
                });

                loading.value = false ;

            }).catch((error) => {

                console.log("Error getting documents: ", error);
                app.setError(error) ;

                loading.value = false ;

            });

        } ;

        const update = (key, value, acco, reload  = false) => {

            console.debug('key', key);
            console.debug('value', value);
            console.debug('acco', acco);

            let data = {
                [key]: value
            }

            firebase.firestore()
            .collection("websites")
            .doc(String(currentWebsite.id()))
            .collection("accommodations")
            .doc(acco.id)
            .set(data, { merge: true })
            .then(() => {
                app.setSuccess(key+' Updated');
                if(reload){ get(); }
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

        } ;

        onMounted(() => {
            get() ;
        });

        const select = (data) => {
            ctx.emit('selected', data);            
        } ;
                
        return {
            app,
            select,
            loading,
            accoData,
            get,
            update,
        }
    
    },

};

</script>